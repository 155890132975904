import { Dispatch, FC, SetStateAction, useState } from 'react'

import logo from '../../assets/img/logo-form.svg'
import Button from '../../compoments/Button'
import FormHeader from '../../compoments/FormHeader'
import Input from '../../compoments/Input'

import Step1 from './components/Step1'
import Step2 from './components/Step2'

export enum SignUpSteps {
  ONE = 1,
  TWO = 2,
}

export interface StepsProps {
  setStep: Dispatch<SetStateAction<SignUpSteps>>
  setActiveFormData: Dispatch<SetStateAction<{ [key: string]: string }>>
  activeFormData: { [key: string]: string }
}

const StepsComponentMap: Record<SignUpSteps, FC<StepsProps>> = {
  [SignUpSteps.ONE]: Step1,
  [SignUpSteps.TWO]: Step2,
}

const SignUpPage: FC = () => {
  const [activeFormData, setActiveFormData] = useState<
    | {
        [key: string]: string
      }
    | {}
  >({})

  const [step, setStep] = useState<SignUpSteps>(SignUpSteps.ONE)
  const ComponentToRender = StepsComponentMap[step]
  return (
    <ComponentToRender
      setStep={setStep}
      setActiveFormData={setActiveFormData}
      activeFormData={activeFormData}
    />
  )
}
export default SignUpPage

import React, { FC, useCallback, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Button from '../../compoments/Button'
import FormHeader from '../../compoments/FormHeader'
import Input from '../../compoments/Input'
import SelectBox from '../../compoments/SelectBox'
import SocialButtons from '../../compoments/SocialButtons'
import { useLoginByPhoneMutation } from '../../services/auth'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { phoneLoginSchema } from '../../shemas/validationSchema'
import { toast } from 'react-toastify'

const LoginPhone: FC = () => {
  const [triggerLoginPhone] = useLoginByPhoneMutation()

  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(phoneLoginSchema),
  })

  const [step, setStep] = useState(1)

  const [loadingReq, setLoadingReq] = useState(false)

  const onSubmit = async (data: { phoneNumber: string; country: string }) => {
    try {
      setLoadingReq(true)
      const res = await triggerLoginPhone({ phone: data.phoneNumber }).unwrap()

      if (res.success) {
        setStep(2)
      } else {
        toast.error(res.message)
      }
    } catch (e) {
    } finally {
      setLoadingReq(false)
    }
  }

  const clearAllInputsRef = useRef<() => void>(() => {})
  const getCodeRef = useRef<() => string>(() => '')

  const inputRef = useCallback((code: HTMLDivElement | null) => {
    if (code !== null) {
      const input_childs = code.querySelectorAll<HTMLInputElement>(
        "input[type='number']",
      )

      if (input_childs.length > 0) {
        const first_input = input_childs[0]
        const form_closest = first_input.closest('form')

        const get_notfocus_inputs = function () {
          const not_focus_inputs_all = code.querySelectorAll<HTMLInputElement>(
            'input:not([autofocus])',
          )
          for (let i = 0; i < not_focus_inputs_all.length; i++) {
            not_focus_inputs_all[i].disabled = true
          }
        }

        if (first_input.getAttribute('autofocus') !== null) {
          get_notfocus_inputs()
        } else {
          first_input.setAttribute('autofocus', 'true')
          get_notfocus_inputs()
        }

        // Function to clear all inputs
        clearAllInputsRef.current = () => {
          input_childs.forEach((input) => {
            input.value = ''
            input.classList.remove('active-value')
            input.disabled = false
          })
          first_input.focus() // Set focus back to the first input
        }

        getCodeRef.current = () => {
          const codeValues = Array.from(input_childs).map(
            (input) => input.value,
          )
          return codeValues.join('') // Join as a single string (or keep as array if preferred)
        }

        for (let i = 0; i < input_childs.length; i++) {
          const inp = input_childs[i]
          inp.addEventListener('input', function (ev) {
            const target = ev?.target as HTMLInputElement
            if (parseInt(target?.value, 10) >= 0) {
              target.classList.add('active-value')
            } else {
              target.classList.remove('active-value')
            }

            const val_inp = target.value
            if (val_inp !== '') {
              inp.disabled = true
              const next_inp = input_childs[i + 1]
              if (next_inp) {
                next_inp.disabled = false
                next_inp.focus()
              } else {
                if (form_closest) {
                  const submit_form =
                    form_closest.querySelector<HTMLButtonElement>(
                      "[type='submit']",
                    )
                  // Optional form submission
                  // submit_form?.click();
                }
              }
            }
          })

          document.addEventListener('keydown', function (ev) {
            ev.stopPropagation()
            if (ev.key === 'e') {
              inp.value = ''
            }
          })

          document.addEventListener('keyup', function (ev) {
            ev.stopPropagation()
            if (ev.key === 'e') {
              inp.value = ''
            }
          })
        }

        // Expose these functions if needed, or bind to buttons for clear or get code actions
        // Example:
        // clearAllInputs();
        // console.log(getCode());
      }
    }
  }, [])

  const submitLastStep = async () => {
    try {
      setLoadingReq(true)
      const res = await triggerLoginPhone({
        phone: getValues('phoneNumber'),
        code: getCodeRef.current(),
      }).unwrap()

      if (res.success) {
        window.localStorage.setItem('token', res.data.token)
        navigate('/')
      } else {
        toast.error(res?.message || 'Something went wrong, try again later')
      }
    } catch (e) {
      toast.error('Something went wrong, try again later')
    } finally {
      setLoadingReq(false)
    }
  }

  return (
    <>
      <FormHeader
        hasLogo
        heading="Login"
        description="Welcome back, fill in form to login account"
      />
      <div className="form-body">
        {step === 1 && (
          <div className="row">
            <div className="col-md-12 col-12 mb-28">
              <div className="row">
                <div className="col-4">
                  <div className="form-group select--outline input--lg">
                    <label className="form-label">
                      <div className="label--text">
                        <span>Country</span>
                      </div>
                    </label>
                    <SelectBox
                      wrapperClassName={`select--outline input--sm standard-height ${errors.country?.message ? 'select-error' : ''}`}
                      options={[{ label: 'US', value: 'US' }]}
                      selectValue={getValues('country')}
                      onChange={(option) =>
                        setValue('country', option?.value as string)
                      }
                    />
                  </div>
                </div>
                <div className="col-8">
                  <Input
                    label="Phone number"
                    {...register('phoneNumber')}
                    error={errors?.phoneNumber?.message}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 mb-44">
              <Button
                disabled={loadingReq}
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Login
              </Button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="row ">
            <div
              className="code  w-full mx-auto "
              ref={inputRef}
              id="code-input"
            >
              <div className="code-col">
                <label className="code-item">
                  <input
                    type="number"
                    inputMode="numeric"
                    name="c0"
                    autoFocus
                  />

                  <div className="code-block"></div>
                </label>
              </div>
              <div className="code-col">
                <label className="code-item">
                  <input type="number" inputMode="numeric" name="c1" />

                  <div className="code-block"></div>
                </label>
              </div>
              <div className="code-col">
                <label className="code-item">
                  <input type="number" inputMode="numeric" name="c2" />

                  <div className="code-block"></div>
                </label>
              </div>
              <div className="code-col">
                <label className="code-item">
                  <input type="number" inputMode="numeric" name="c3" />

                  <div className="code-block"></div>
                </label>
              </div>
              <div className="code-col">
                <label className="code-item">
                  <input type="number" inputMode="numeric" name="c4" />

                  <div className="code-block"></div>
                </label>
              </div>
              <div className="code-col">
                <label className="code-item">
                  <input type="number" inputMode="numeric" name="c5" />

                  <div className="code-block"></div>
                </label>
              </div>
            </div>
            <div className="col-12 mb-44" style={{ marginTop: '80px' }}>
              <Button
                disabled={loadingReq}
                className="mb-28"
                onClick={(e) => {
                  e.preventDefault()
                  clearAllInputsRef.current()
                }}
              >
                Reset code
              </Button>
              <div className="col-12 ">
                <Button
                  type="button"
                  disabled={loadingReq}
                  onClick={submitLastStep}
                >
                  Login
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="form-footer">
        <div className="row">
          <div className="col-12 mb-28">
            <SocialButtons button="email" />
          </div>
          <div className="col-12">
            <div className="flex items-center justify-center gap-10">
              <span className="text--md">Don’t have an account?</span>
              <Link
                to="/create-account"
                className="color-secondary text--md fw-600"
              >
                Create an Account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default LoginPhone

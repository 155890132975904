import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import type { StepsProps } from '..'
import logo from '../../../assets/img/logo-form.svg'
import Button from '../../../compoments/Button'
import Checkbox, { CheckboxSizes } from '../../../compoments/Checkbox'
import FormHeader from '../../../compoments/FormHeader'
import Input from '../../../compoments/Input'
import PasswordInput from '../../../compoments/PasswordInput'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  emailSchema,
  passwordSchema,
  resetPasswordSchema,
} from '../../../shemas/validationSchema'
import {
  useChangePasswordMutation,
  useForgotPasswordMutation,
} from '../../../services/auth'
import { toast } from 'react-toastify'

const stepTexts = {
  1: {
    heading: 'Reset password',
    description: 'No worries, we’ll send you instructions.',
  },
  2: {
    heading: 'Enter your code',
    description: (
      <span>
        We sent a code to <br />{' '}
        <a
          className="color-secondary fw-600 lh-26"
          href="mailto:jackson.graham@example.com"
        >
          jackson.graham@example.com
        </a>
      </span>
    ),
  },
  3: {
    heading: 'Set new password',
    description: 'Must be at least 8 characters',
  },
}

const Step2: React.FC<StepsProps> = ({ setStep }) => {
  const [resetStep, setResetStep] = useState<number>(1)

  const navigate = useNavigate()

  const clearAllInputsRef = useRef<() => void>(() => {})
  const getCodeRef = useRef<() => string>(() => '')

  const [sendRequestLoading, setSendRequestLoading] = useState(false)

  const inputRef = useCallback((code: HTMLDivElement | null) => {
    if (code !== null) {
      const input_childs = code.querySelectorAll<HTMLInputElement>(
        "input[type='number']",
      )

      if (input_childs.length > 0) {
        const first_input = input_childs[0]
        const form_closest = first_input.closest('form')

        const get_notfocus_inputs = function () {
          const not_focus_inputs_all = code.querySelectorAll<HTMLInputElement>(
            'input:not([autofocus])',
          )
          for (let i = 0; i < not_focus_inputs_all.length; i++) {
            not_focus_inputs_all[i].disabled = true
          }
        }

        if (first_input.getAttribute('autofocus') !== null) {
          get_notfocus_inputs()
        } else {
          first_input.setAttribute('autofocus', 'true')
          get_notfocus_inputs()
        }

        // Function to clear all inputs
        clearAllInputsRef.current = () => {
          input_childs.forEach((input) => {
            input.value = ''
            input.classList.remove('active-value')
            input.disabled = false
          })
          first_input.focus() // Set focus back to the first input
        }

        getCodeRef.current = () => {
          const codeValues = Array.from(input_childs).map(
            (input) => input.value,
          )
          return codeValues.join('') // Join as a single string (or keep as array if preferred)
        }

        for (let i = 0; i < input_childs.length; i++) {
          const inp = input_childs[i]
          inp.addEventListener('input', function (ev) {
            const target = ev?.target as HTMLInputElement
            if (parseInt(target?.value, 10) >= 0) {
              target.classList.add('active-value')
            } else {
              target.classList.remove('active-value')
            }

            const val_inp = target.value
            if (val_inp !== '') {
              inp.disabled = true
              const next_inp = input_childs[i + 1]
              if (next_inp) {
                next_inp.disabled = false
                next_inp.focus()
              } else {
                if (form_closest) {
                  const submit_form =
                    form_closest.querySelector<HTMLButtonElement>(
                      "[type='submit']",
                    )
                  // Optional form submission
                  // submit_form?.click();
                }
              }
            }
          })

          document.addEventListener('keydown', function (ev) {
            ev.stopPropagation()
            if (ev.key === 'e') {
              inp.value = ''
            }
          })

          document.addEventListener('keyup', function (ev) {
            ev.stopPropagation()
            if (ev.key === 'e') {
              inp.value = ''
            }
          })
        }

        // Expose these functions if needed, or bind to buttons for clear or get code actions
        // Example:
        // clearAllInputs();
        // console.log(getCode());
      }
    }
  }, [])

  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  })

  console.log(errors)

  const [resetPasswordMail] = useForgotPasswordMutation()
  const [resetPassword] = useChangePasswordMutation()

  const submitFirstStep = async () => {
    const email = getValues('email')
    try {
      setSendRequestLoading(true)
      await emailSchema.validate({ email })

      const res = await resetPasswordMail({ email }).unwrap()

      if (res.success) {
        setResetStep(2)
      }
    } catch (validationError) {
      setError('email', {
        type: 'manual',
        message: validationError.errors[0],
      })
    } finally {
      setSendRequestLoading(false)
    }
  }

  const confirmStepTwo = () => {
    const code = getCodeRef.current()
    if (!code || code.length !== 6) return

    setResetStep(3)
  }

  const confirmStepThree = async (data: {
    email: string
    password: string
  }) => {
    const obj = {
      email: data.email,
      password: data.password,
      code: getCodeRef.current(),
    }

    try {
      setSendRequestLoading(true)

      const res = await resetPassword(obj).unwrap()

      if (res.success) {
        window.localStorage.setItem('token', res.token)
        toast.success('Password reset successfully')
        navigate('/')
      }
    } catch (e) {
    } finally {
      setSendRequestLoading(false)
    }
  }

  return (
    <>
      <FormHeader
        hasArrow
        handleBack={(e) => {
          e.preventDefault()
          if (resetStep === 1) {
            setStep(1)
          } else {
            setResetStep(resetStep - 1)
          }
        }}
        heading={stepTexts[resetStep as keyof typeof stepTexts].heading}
        description={
          resetStep === 2 ? (
            <span>
              We sent a code to <br />{' '}
              <a
                className="color-secondary fw-600 lh-26"
                href={`mailto:${getValues('email')}`}
              >
                {getValues('email')}
              </a>
            </span>
          ) : (
            stepTexts[resetStep as keyof typeof stepTexts].description
          )
        }
      />
      <div className="form-body">
        <div className="row">
          {resetStep === 1 && (
            <div className="col-md-12 col-12 mb-22">
              <Input
                label="Email"
                placeholder="Enter your email"
                {...register('email')}
                error={errors?.email?.message}
              />
            </div>
          )}
          {resetStep === 2 && (
            <div
              className="code  w-full mx-auto "
              ref={inputRef}
              id="code-input"
            >
              <div className="code-col">
                <label className="code-item">
                  <input
                    type="number"
                    inputMode="numeric"
                    name="c0"
                    autoFocus
                  />

                  <div className="code-block"></div>
                </label>
              </div>
              <div className="code-col">
                <label className="code-item">
                  <input type="number" inputMode="numeric" name="c1" />

                  <div className="code-block"></div>
                </label>
              </div>
              <div className="code-col">
                <label className="code-item">
                  <input type="number" inputMode="numeric" name="c2" />

                  <div className="code-block"></div>
                </label>
              </div>
              <div className="code-col">
                <label className="code-item">
                  <input type="number" inputMode="numeric" name="c3" />

                  <div className="code-block"></div>
                </label>
              </div>
              <div className="code-col">
                <label className="code-item">
                  <input type="number" inputMode="numeric" name="c4" />

                  <div className="code-block"></div>
                </label>
              </div>
              <div className="code-col">
                <label className="code-item">
                  <input type="number" inputMode="numeric" name="c5" />

                  <div className="code-block"></div>
                </label>
              </div>
            </div>
          )}
          {resetStep === 3 && (
            <>
              <div className="col-md-12 col-12 mb-22">
                <PasswordInput
                  label="Password"
                  {...register('password')}
                  error={errors?.password?.message}
                />
              </div>

              <div className="col-md-12 col-12 mb-28">
                <PasswordInput
                  label="Confirm password"
                  {...register('confirmPassword')}
                  error={errors?.confirmPassword?.message}
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="form-footer">
        <div className="row">
          {resetStep === 1 && (
            <div className="col-12">
              <Button
                disabled={sendRequestLoading}
                type="submit"
                onClick={(e) => {
                  e.preventDefault()
                  submitFirstStep()
                }}
              >
                Reset Password
              </Button>
            </div>
          )}
          {resetStep === 2 && (
            <div className="col-12">
              <Button
                className="mb-28"
                onClick={(e) => {
                  e.preventDefault()
                  clearAllInputsRef.current()
                }}
              >
                Reset code
              </Button>
              <Button
                type="submit"
                onClick={(e) => {
                  e.preventDefault()
                  confirmStepTwo()
                  // setResetStep(3)
                }}
                className="mb-28"
              >
                Submit
              </Button>
              <div className="flex items-center justify-center gap-10">
                <span className="text--md">Don’t receive the email?</span>
                <button
                  onClick={(e) => {
                    e.preventDefault()
                    setResetStep(1)
                  }}
                  className="color-secondary text--md fw-600"
                >
                  Resend
                </button>
              </div>
            </div>
          )}
          {resetStep === 3 && (
            <div className="col-12">
              <Button
                type="submit"
                onClick={(e) => {
                  e.preventDefault()
                  handleSubmit(confirmStepThree)()
                }}
              >
                Set New Password
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default Step2

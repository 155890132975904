import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'

import SettingsIcon from '../assets/img/ico-gear.svg?react'
import HomeIcon from '../assets/img/ico-home.svg?react'
import LogoutIcon from '../assets/img/ico-logout.svg?react'
import NotificationsSecondaryIcon from '../assets/img/ico-notification-2.svg?react'
import AlertsAdministrationIcon from '../assets/img/ico-squares-four.svg?react'
import CaretdownIcon from '../assets/img/icon-caretdown.svg?react'
import NotificationsIcon from '../assets/img/icon-notifications.svg?react'
import Logo from '../assets/img/logo-header.svg?react'

import Dropdown from './Dropdown'
import SearchForm from './SearchForm'
import { useLazyGetUsersQuery } from '../services/users'
import { authApi, useGetMeQuery } from '../services/auth'
import { useDispatch } from 'react-redux'
import { IUser } from '../types'
import AgeRangeIcon from '../assets/img/ico-age-range.svg?react'
import {
  useGetAllNotificationsQuery,
  useLazyCountNotQuery,
} from '../services/notifications'

const DropdownButton = ({ avatar }: { avatar: string }) => (
  <>
    <div className="ico ico--sm primary-user-ico rounded-full overflow-hidden">
      {avatar ? (
        <img src={`${import.meta.env.VITE_API_URL}` + avatar} alt="user" />
      ) : (
        <AgeRangeIcon />
      )}
    </div>

    <span className="ico --size-22 justify-center btn-round-light drp-arrow-rotate">
      <span className="ico --size-12 justify-center">
        <CaretdownIcon />
      </span>
    </span>
  </>
)

const DropdownMenu = ({
  onLogout,
  data,
}: {
  onLogout: () => void
  data: IUser
}) => {
  const routes = [
    {
      name: 'Home',
      to: '/',
      icon: <HomeIcon />,
    },
    {
      name: 'Alerts Administration',
      to: '/alerts',
      icon: <AlertsAdministrationIcon />,
    },
    {
      name: 'Notifications',
      to: '/notifications',
      icon: <NotificationsSecondaryIcon />,
    },
    {
      name: 'Settings',
      to: '/settings',
      icon: <SettingsIcon />,
    },
  ]

  return (
    <>
      <div className="dropdown-content__header">
        <div className="dropdown-content__avatar">
          {data?.photo ? (
            <img
              src={`${import.meta.env.VITE_API_URL}` + data.photo}
              alt="user"
            />
          ) : (
            <AgeRangeIcon />
          )}
        </div>
        <div className="flex flex-col text-center">
          <span className="dropdown-content__user-name">{data?.fullName}</span>
          <span className="dropdown-content__user-email">{data?.email}</span>
        </div>
      </div>
      <div className="dropdown-content__settings">
        {routes.map((route, index) => (
          <NavLink
            to={route.to}
            className={({ isActive }) =>
              clsx('dropdown-content__setting-link', {
                'active-page': isActive,
              })
            }
            key={index}
          >
            <div className="setting-link-group-text">
              <span className="setting-link-icon">{route.icon}</span>
              {route.name}
            </div>
          </NavLink>
        ))}
        <button onClick={onLogout} className="dropdown-content__setting-link">
          <div className="setting-link-group-text">
            <span className="setting-link-icon">
              <LogoutIcon />
            </span>
            Log Out
          </div>
        </button>
      </div>
    </>
  )
}

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navigate = useNavigate()
  const { data } = useGetMeQuery()

  const [notificationsCount, setNotificationsCount] = useState(0)

  const { data: notificationsData, refetch } = useGetAllNotificationsQuery()

  const [triggerGetNotCount] = useLazyCountNotQuery()

  const [firstInit, setFirstInit] = useState(false)

  const dispatch = useDispatch()

  const isAuthenticated = data?.data?.id

  const handleLogout = () => {
    window.localStorage.removeItem('token')
    dispatch(authApi.util.resetApiState())
    navigate('/login-email')
  }

  useEffect(() => {
    const req = async () => {
      try {
        const res = await triggerGetNotCount().unwrap()

        if (res?.data?.count > notificationsCount) {
          refetch()
          setNotificationsCount(res?.data?.count)
        }
      } catch (e) {}
    }

    const interval = setInterval(() => {
      req()
    }, 60000)

    return () => clearInterval(interval)
  }, [notificationsData, triggerGetNotCount, refetch, notificationsCount])

  const [triggerGetUsers] = useLazyGetUsersQuery()

  const [nearByZip, setNearByZip] = useState(null)

  const searchRequest = ({
    value,
    isName,
  }: {
    value: string
    isName: boolean
  }) => {
    if (isName) {
      triggerGetUsers({ name: value })
    } else {
      triggerGetUsers({ zip: value })
    }
  }

  useEffect(() => {
    if (!nearByZip) return
    if (firstInit) return
    // if (!isAuthenticated) return

    setFirstInit(true)

    searchRequest({ value: nearByZip as string, isName: false })
  }, [isAuthenticated, firstInit, nearByZip])

  const getZipCode = (latitude: number, longitude: number) => {
    const accessToken = import.meta.env.VITE_MAP_BOX_KEY
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}`

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.features.length > 0) {
          const postalCodeFeature = data.features.find((feature: any) =>
            feature.place_type.includes('postcode'),
          )

          if (postalCodeFeature) {
            console.log(`ZIP code: ${postalCodeFeature.text}`)
            setNearByZip(postalCodeFeature.text)
          } else {
            console.error('Postal code not found.')
          }
        }
      })
      .catch((error) => console.error('Error with Mapbox API:', error))
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude

        getZipCode(latitude, longitude)
      },
      (error) => {
        searchRequest({ value: '20190', isName: false })
        console.error('Geolocation error:', error)
      },
    )
  }, [])

  return (
    <header className="header">
      <div className="container">
        <div className="header__body">
          <NavLink to={'/'} className="header__logo">
            <Logo />
          </NavLink>

          <div className={clsx('header__menu', { active: isMenuOpen })}>
            <SearchForm
              onSearch={(value, isName) => {
                searchRequest({ value, isName })
              }}
            />
            {isAuthenticated ? (
              <div className="hrader-notifications-group">
                <Link to={'/notifications'} className="hrader-notifications">
                  <NotificationsIcon />
                  {notificationsData?.data?.length ? (
                    <span className="hrader-notifications__cunter">
                      {notificationsData?.data?.length}
                    </span>
                  ) : null}
                </Link>
                <span className="decor-delimiter"></span>
                <Dropdown
                  buttonClassName={'btn user-dropdown-btn px-10px'}
                  button={<DropdownButton avatar={data?.data?.photo || ''} />}
                  menuClassName={'dropdown__body__login radius-18'}
                  menu={
                    <DropdownMenu onLogout={handleLogout} data={data?.data} />
                  }
                />
              </div>
            ) : (
              <Link
                to={'/create-account'}
                className="btn btn--lg btn--primary rounded-full"
              >
                Sign Up / Login
              </Link>
            )}
          </div>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className={clsx('header__burger', { active: isMenuOpen })}
          >
            <span></span>
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header

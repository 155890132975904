import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import React, { useEffect, useRef, useState } from 'react'

import photo from '../../../assets/img/user-ava-98x98.png'
import { IOffender } from '../../../types'
const mapBoxToken = import.meta.env.VITE_MAP_BOX_KEY
mapboxgl.accessToken = mapBoxToken

interface MarkerData {
  lngLat: [number, number]
  title: string
  distanceTime: number
  photo: string
}

const initMap = (
  mapContainer: HTMLDivElement | null,
  markersData: MarkerData[],
  userLocation: [number, number] | null,
) => {
  if (!mapContainer) return

  const map = new mapboxgl.Map({
    container: mapContainer,
    style: 'mapbox://styles/maximokolzin/clxd74ktr02dc01pnb0ra8sd0',
    center: userLocation || [-78.16, 39.197372],
    zoom: 15.5,
  })

  const myLocation: [number, number] = userLocation || [-78.16, 39.197372]

  const createHomeMarkerElement = (): HTMLDivElement => {
    const el = document.createElement('div')
    el.innerHTML = `
      <div class="home-marker">
        <div class="home-marker__inner">
          <div class="ico">
            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_21_2302)">
                <path d="M21.125 17.875H19.75V11.6875L19.9511 11.8886C20.0803 12.0176 20.2555 12.09 20.4381 12.0898C20.6207 12.0896 20.7958 12.0169 20.9248 11.8877C21.0538 11.7585 21.1262 11.5833 21.126 11.4007C21.1258 11.2181 21.0531 11.043 20.9239 10.914L12.472 2.46467C12.2141 2.207 11.8645 2.06226 11.5 2.06226C11.1355 2.06226 10.7859 2.207 10.528 2.46467L2.07609 10.914C1.9472 11.043 1.87484 11.218 1.87492 11.4003C1.875 11.5827 1.94752 11.7575 2.07652 11.8864C2.20553 12.0153 2.38045 12.0877 2.5628 12.0876C2.74516 12.0875 2.92002 12.015 3.04891 11.886L3.25 11.6875V17.875H1.875C1.69266 17.875 1.5178 17.9474 1.38886 18.0763C1.25993 18.2053 1.1875 18.3801 1.1875 18.5625C1.1875 18.7448 1.25993 18.9197 1.38886 19.0486C1.5178 19.1775 1.69266 19.25 1.875 19.25H21.125C21.3073 19.25 21.4822 19.1775 21.6111 19.0486C21.7401 18.9197 21.8125 18.7448 21.8125 18.5625C21.8125 18.3801 21.7401 18.2053 21.6111 18.0763C21.4822 17.9474 21.3073 17.875 21.125 17.875ZM13.5625 17.875H9.4375V13.75C9.4375 13.6588 9.47372 13.5714 9.53818 13.5069C9.60265 13.4424 9.69008 13.4062 9.78125 13.4062H13.2188C13.3099 13.4062 13.3974 13.4424 13.4618 13.5069C13.5263 13.5714 13.5625 13.6588 13.5625 13.75V17.875Z" fill="#0A3313"/>
              </g>
              <defs>
                <clipPath id="clip0_21_2302">
                  <rect width="22" height="22" fill="white" transform="translate(0.5)"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    `
    return el
  }

  const createPersonMarkerElement = (
    distanceTime: number,
    photo: string,
  ): HTMLDivElement => {
    const el = document.createElement('div')
    const imagePath = photo
    el.innerHTML = `
      <div class="person-marker">
        <div>
          <img src="${imagePath}" alt="" />
        </div>
<!--        <span class="pill">${distanceTime}min</span>-->
      </div>
    `
    return el
  }

  new mapboxgl.Marker(createHomeMarkerElement())
    .setLngLat(myLocation)
    .addTo(map)

  markersData.forEach((markerData) => {
    new mapboxgl.Marker(
      createPersonMarkerElement(markerData.distanceTime, markerData.photo),
    )
      .setLngLat(markerData.lngLat)
      // .setPopup(
      //   new mapboxgl.Popup({ offset: 25 }).setText(
      //     `Distance: ${markerData.distanceTime} minutes`,
      //   ),
      // )
      .addTo(map)
  })

  map.addControl(new mapboxgl.NavigationControl(), 'bottom-right')
  map.on('load', () => {
    map.resize()
  })

  return map
}

const Map: React.FC<{ offenders: IOffender[] }> = ({ offenders }) => {
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const [userLocation, setUserLocation] = useState<[number, number] | null>(
    null,
  )

  const markers = offenders.map((el) => {
    const address = el.address[0].location

    return {
      lngLat: [+address.lon, +address.lat] as [number, number],
      title: 'title',
      distanceTime: 10,
      photo:
        import.meta.env.VITE_API_URL +
        '/api/radar-service/files/' +
        el.image_src,
    }
  })

  useEffect(() => {
    // Получаем текущую геопозицию пользователя
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords
        setUserLocation([longitude, latitude])
      },
      (error) => {
        console.error('Error getting user location:', error)
        setUserLocation(null) // Если не удалось получить координаты, оставляем null
      },
    )
  }, [])

  useEffect(() => {
    const map = initMap(mapContainerRef.current, markers, userLocation)

    return () => map && map.remove()
  }, [offenders, userLocation])

  return (
    <div
      ref={mapContainerRef}
      id="map"
      className="map-container"
      style={{ width: '100%', height: '100%' }}
    />
  )
}

export default Map

import clsx from 'clsx'
import { useEffect, useState } from 'react'

import PlusIcon from '../../assets/img/ico-calc-plus.svg?react'
import Button from '../../compoments/Button'
import Pagination from '../../compoments/Pagination'
import { useTabs } from '../../hooks/useTabs'

import AddAlertModal from './components/AddAlertModal'
import NameAlertsTable from './components/NameAlertsTable'
import ZipCodeAlertsTable from './components/ZipCodeAlertsTable'
import { useGetMeQuery } from '../../services/auth'
import { useDeleteZipAlertMutation } from '../../services/notifications'
import { useGetProfilesUsersMutation } from '../../services/users'

const AlertsPage = () => {
  const tabs = [
    { id: 'name_alerts', label: 'Name Alerts' },
    { id: 'zip_codes_alerts', label: 'ZIP Codes Alerts' },
  ] as const

  const { activeTab, setActiveTab } = useTabs(tabs)
  const [isAddAlertModalOpen, setIsAddAlertModalOpen] = useState(false)
  const [activeAlert, setActiveAlert] = useState(null)

  const [triggerGetUsersProfiles] = useGetProfilesUsersMutation()
  const { data: userData, refetch } = useGetMeQuery()

  console.log(userData, 'user data')

  // const nameAlerts = Array.from({ length: 12 }).map((_, index) => ({
  //   id: index,
  //   name: 'Bessie Cooper',
  //   address: '1901 Thornridge Cir. Shiloh, Hawaii',
  // }))

  const [nameAlerts, setNameAlerts] = useState<[] | any>([])

  const [zipCodes, setZipCodes] = useState<
    [] | { zip: string; range: number }[]
  >([])

  useEffect(() => {
    if (userData?.data?.zipcodes) {
      const arr = Object.entries(userData.data.zipcodes).map(
        ([key, value]) => ({
          zip: key as string,
          range: value as number,
        }),
      )
      setZipCodes(arr)
    }

    const req = async (arr: string[]) => {
      const res = await triggerGetUsersProfiles({
        offenderDocIds: arr,
      }).unwrap()

      if (res) {
        setNameAlerts(res.offenders)
      }

      console.log(res, '111111111111230812039812093123')
    }

    if (userData?.data?.offenders) {
      req(userData?.data?.offenders)
    }
  }, [userData?.data])

  const editAlertZip = (alert: any) => {
    setIsAddAlertModalOpen(true)
    setActiveAlert(alert)
  }

  return (
    <main className="content">
      <div className="container h-full">
        <div className="catalog-page section-fullscreen headers-similars">
          <div className="catalog-page-content w-full">
            <div className="card card-border h-full">
              <div className="card-header pb-0 border-none">
                <div className="card-header--item">
                  <h1 className="heading font-500">Alerts Administration</h1>
                </div>

                <div className="card-header--item">
                  {activeTab === 'zip_codes_alerts' ? (
                    <Button onClick={() => setIsAddAlertModalOpen(true)}>
                      <span className="ico">
                        <PlusIcon />
                      </span>
                      Add Alert
                    </Button>
                  ) : null}
                </div>
              </div>
              <div className="card-body overflow-hidden">
                <div className="table-block h-full">
                  <div className="table-header tabs-nav">
                    {tabs.map((tab) => (
                      <button
                        key={tab.id}
                        className={clsx('tab-link-2 btn btn--md', {
                          'active-tab': activeTab === tab.id,
                        })}
                        onClick={() => setActiveTab(tab.id)}
                      >
                        <div className="tab-link-2--content text--lg">
                          <span>{tab.label}</span>
                          <span className="count-pill">
                            {tab.id === 'zip_codes_alerts'
                              ? zipCodes.length
                              : nameAlerts.length}
                          </span>
                        </div>
                      </button>
                    ))}
                  </div>
                  <div className="table-body overflow-hidden h-full">
                    {activeTab === 'name_alerts' && (
                      <div className="tab h-full active-tab">
                        <NameAlertsTable
                          alerts={nameAlerts}
                          onDelete={() => {}}
                        />
                      </div>
                    )}
                    {activeTab === 'zip_codes_alerts' && (
                      <div className="tab h-full active-tab">
                        <ZipCodeAlertsTable
                          alerts={zipCodes}
                          onDelete={() => {}}
                          onEdit={(alert) => editAlertZip(alert)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <Pagination
                  pageCount={1}
                  currentPage={1}
                  onPageChange={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddAlertModal
        onSubmit={() => {}}
        isOpen={isAddAlertModalOpen}
        activeAlert={activeAlert}
        onClose={() => {
          setActiveAlert(null)
          setIsAddAlertModalOpen(false)
        }}
      />
    </main>
  )
}

export default AlertsPage

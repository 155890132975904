import { createSlice } from '@reduxjs/toolkit'
import { usersApi } from '../services/users'

interface IState {
  offendersAllData: any
  error: string | null
  isLoading: boolean
}

const initialState: IState = {
  offendersAllData: null,
  isLoading: false,
  error: null,
}

const offendersSlice = createSlice({
  name: 'offenders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(usersApi.endpoints.getUsers.matchPending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addMatcher(
        usersApi.endpoints.getUsers.matchFulfilled,
        (state, { payload }) => {
          state.isLoading = false
          if (payload.messages) {
            state.offendersAllData = null
          } else {
            state.offendersAllData = payload
          }
        },
      )
      .addMatcher(
        usersApi.endpoints.getUsers.matchRejected,
        (state, { error }) => {
          state.isLoading = false
          state.error = error.message || 'An error occurred'
        },
      )
  },
})

export default offendersSlice.reducer

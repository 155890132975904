import { Link } from 'react-router-dom'

import AppleIcon from '../assets/img/icon-apple.svg?react'
import CallIcon from '../assets/img/icon-call.svg?react'
import EmailIcon from '../assets/img/icon-email.svg?react'
import FacebookIcon from '../assets/img/icon-facebook.svg?react'
import GoogleIcon from '../assets/img/icon-google.svg?react'
import { useLazyLoginGoogleQuery } from '../services/auth'

interface SocialButtonsProps {
  button: 'call' | 'email'
}

const SocialButtons: React.FC<SocialButtonsProps> = ({ button }) => {
  const [triggerLoginGoogle] = useLazyLoginGoogleQuery()

  return (
    <div className="servis-link-group ">
      <a
        href={import.meta.env.VITE_API_URL + 'oauth/google'}
        // onClick={(e) => {
        //   e.preventDefault()
        //
        //   triggerLoginGoogle()
        // }}
        className="servis-link ico ico--xl rounded-full"
      >
        <GoogleIcon />
      </a>
      <button
        onClick={(e) => {
          e.preventDefault()
        }}
        className="servis-link ico ico--xl rounded-full"
      >
        <FacebookIcon />
      </button>
      <button
        onClick={(e) => {
          e.preventDefault()
        }}
        className="servis-link ico ico--xl rounded-full"
      >
        <AppleIcon />
      </button>
      <button
        onClick={(e) => e.preventDefault()}
        className="servis-link ico ico--xl rounded-full"
      >
        {button === 'call' ? (
          <Link to="/login-phone">
            <CallIcon />
          </Link>
        ) : (
          <Link to="/login-email">
            <EmailIcon />
          </Link>
        )}
      </button>
    </div>
  )
}

export default SocialButtons

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import React from 'react'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  // if (!isOpen) return null
  return (
    <div
      id="modal"
      className={clsx('modal modal-1', { 'modal--show': isOpen })}
      onClick={onClose}
    >
      <div
        className="modal__dialog modal__dialog--md"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal__content">{children}</div>
      </div>
    </div>
  )
}

export default Modal

import React from 'react'
import { Link } from 'react-router-dom'

const NotFound: React.FC = () => {
  return (
    <main className="content">
      <div className="container h-full">
        <div className="section-center">
          <div className="m-auto text-center">
            <h1 className="heading">404</h1>
            <p className="mt-4">Page Not Found</p>
            <Link
              to={'/'}
              className="btn btn--lg btn--primary rounded-full mt-4"
            >
              Go back to Home
            </Link>
          </div>
        </div>
      </div>
    </main>
  )
}

export default NotFound

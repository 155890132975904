/* eslint-disable jsx-a11y/label-has-associated-control */
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import MinusIcon from '../../../assets/img/ico-calc-minus.svg?react'
import PlusIcon from '../../../assets/img/ico-calc-plus.svg?react'
import Modal from '../../../compoments/Modal'
import { useAddUpdateZipAlertMutation } from '../../../services/notifications'
import { useGetMeQuery } from '../../../services/auth'

interface AddAlertModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: (alert: { zipCode: string; milesRange: number }) => void
  activeAlert: any
}

const AddAlertModal = ({
  isOpen,
  onClose,
  onSubmit,
  activeAlert,
}: AddAlertModalProps) => {
  const [zipCode, setZipCode] = useState('')
  const [range, setRange] = useState(0)

  const [triggerAddAllert] = useAddUpdateZipAlertMutation()
  const { refetch } = useGetMeQuery()
  const [isLoadingReq, setIsLoadingReq] = useState(false)

  const isUpdateMode = !!activeAlert

  useEffect(() => {
    if (activeAlert) {
      setZipCode(activeAlert.zip)
      setRange(activeAlert.range)
    }
  }, [activeAlert])

  const handleSubmit = async () => {
    if (zipCode && range > 0) {
      // onSubmit({ zipCode, milesRange: range })
      // onClose()

      try {
        console.log('1111')
        setIsLoadingReq(true)
        const res = await triggerAddAllert({
          zipcodes: { [`${zipCode}`]: range },
        }).unwrap()

        if (res.success) {
          setZipCode('')
          setRange(0)
          await refetch()
          onClose()
          if (isUpdateMode) {
            toast.success('Zip code updated successfully.')
          } else {
            toast.success('Zip code added successfully.')
          }
        } else {
          toast.error(res.message || 'Something went wrong.')
        }

        console.log(res, 'res 000099991111')
      } catch (e) {
      } finally {
        setIsLoadingReq(false)
      }
    } else {
      toast.error('Please enter a valid ZIP code and range')
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="modal__header">
        <div className="modal__header-block">
          <h2 className="modal__header-title">Add Alert</h2>
          <p className="modal__header-description">
            Fill in the form to create a new ZIP code alert.
          </p>
        </div>
      </div>
      <div className="modal__body">
        <div className="form-group input--icon-right input--lg mb-22">
          <label className="label">ZIP code</label>
          <div className="input-wrapper">
            <input
              className="input input--solid w-full"
              type="text"
              placeholder="Enter ZIP code..."
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
          </div>
        </div>
        <div className="form-group input--icon-right input--lg">
          <label className="label">Set range</label>
          <div className="row-group gap--lg">
            <div
              className={clsx('calculator', { '--null': range === 0 })}
              data-calculator
            >
              <button
                onClick={() => setRange((prev) => prev + 1)}
                type="button"
                className="btn btn-calculator btn--square
                            btn-calc--plus btn--outline-default rounded-full"
              >
                <span className="ico">
                  <PlusIcon />
                </span>
              </button>
              <input
                type="text"
                value={range}
                data-start="0"
                data-calculator--input=""
                className="input"
                readOnly
              />
              <button
                onClick={() => setRange((prev) => Math.max(0, prev - 1))}
                type="button"
                className="btn btn-calculator btn--square
                            btn-calc--minus btn--outline-default rounded-full"
              >
                <span className="ico">
                  <MinusIcon />
                </span>
              </button>
            </div>
            <span className="color-text">miles</span>
          </div>
        </div>
      </div>
      <div className="modal__footer">
        <button
          onClick={onClose}
          className="btn btn--secondary-light btn--lg rounded-full"
        >
          Cancel
        </button>
        <button
          disabled={isLoadingReq}
          onClick={handleSubmit}
          className="btn btn--primary btn--lg rounded-full"
        >
          {isUpdateMode ? 'Update Alert' : 'Add Alert'}
        </button>
      </div>
    </Modal>
  )
}

export default AddAlertModal

import clsx from 'clsx'
import React from 'react'

import NextIcon from '../assets/img/ico-pagination--next.svg?react'
import PrevIcon from '../assets/img/ico-pagination--prev.svg?react'

interface PaginationProps {
  pageCount: number
  currentPage: number
  onPageChange: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
  pageCount,
  currentPage,
  onPageChange,
}) => {
  const renderPaginationItems = () => {
    const items = []

    for (let i = 1; i <= pageCount; i++) {
      if (i === 4 || i === 5 || i === 6) {
        if (i === 4) {
          items.push(
            <li key="ellipsis" className="pagination-item">
              <button
                className="btn btn--square btn--sm rounded-full btn-pagination"
                disabled
              >
                <span>...</span>
              </button>
            </li>,
          )
        }
      } else {
        items.push(
          <li key={i} className="pagination-item">
            <button
              onClick={() => onPageChange(i)}
              className={clsx(
                'btn btn--square btn--sm rounded-full btn-pagination',
                {
                  '--active': i === currentPage,
                },
              )}
            >
              <span>{i}</span>
            </button>
          </li>,
        )
      }
    }

    return items
  }

  return (
    <div className="pagination">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        className="btn btn--square btn--sm rounded-full btn-pagination btn-pag--nav"
        disabled={currentPage === 1}
      >
        <span className="ico">
          <PrevIcon />
        </span>
      </button>
      <ul className="pagination-list">{renderPaginationItems()}</ul>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        className="btn btn--square btn--sm rounded-full btn-pagination btn-pag--nav"
        disabled={currentPage === pageCount}
      >
        <span className="ico">
          <NextIcon />
        </span>
      </button>
    </div>
  )
}

export default Pagination

import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'

import AgeRangeIcon from '../../../assets/img/ico-age-range.svg?react'
import EarthIcon from '../../../assets/img/ico-earth.svg?react'
import FilterIcon from '../../../assets/img/ico-filter.svg?react'
import MagnifyIcon from '../../../assets/img/ico-magnify.svg?react'
import ZipCodeIcon from '../../../assets/img/ico-zipcode.svg?react'
import Checkbox from '../../../compoments/Checkbox'
import InputRange from '../../../compoments/InputRange'

import Accordion from './Accordion'
import { stateDataObj } from '../../../staticData/states'
import { useSearchParams } from 'react-router-dom'
import { SearchFilters } from '../index'
import { useAppSelector } from '../../../hooks/redux'

type FilterCategory = 'states' | 'zipcodes' | 'ranges'

interface OptionObj {
  label: string
  value: string
}

interface FilterOptions {
  title: string
  icon: React.ReactElement
  options?: OptionObj[] | []
  min?: number
  max?: number
  defaultValues?: number[]
}

const filters: Record<FilterCategory, FilterOptions> = {
  states: {
    title: 'State',
    icon: <EarthIcon />,
    options: [],
    defaultValues: [],
  },
  zipcodes: {
    title: 'ZIP Codes',
    icon: <ZipCodeIcon />,
    options: [],
    defaultValues: [],
  },
  ranges: {
    title: 'Age Range',
    icon: <AgeRangeIcon />,
    min: 0,
    max: 100,
    defaultValues: [0, 100],
  },
}

const SidebarFilters = () => {
  const isFirstRender = useRef(true)

  const [selectedFilters, setSelectedFilters] = useState({
    states: [] as string[],
    zipcodes: [] as string[],
    ranges: [] as number[],
  })
  const [search, setSearch] = useState({
    states: '',
    zipcodes: '',
  })

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (isFirstRender.current) {
      const states = searchParams.get(SearchFilters.STATES) || ''
      const zips = searchParams.get(SearchFilters.ZIPCODES) || ''
      const range = searchParams.get(SearchFilters.RANGE) || ''

      setSelectedFilters((prev) => ({
        ...prev,
        states: states ? states.split(',') : [],
        zipcodes: zips ? zips.split(',') : [],
        ranges: range ? range.split(',').map((el) => +el) : [],
      }))
      isFirstRender.current = false
    } else {
      searchParams.set(SearchFilters.STATES, selectedFilters.states.join(','))
      searchParams.set(
        SearchFilters.ZIPCODES,
        selectedFilters.zipcodes.join(','),
      )
      searchParams.set(SearchFilters.RANGE, selectedFilters.ranges.join(','))

      setSearchParams(searchParams)
    }
  }, [selectedFilters])

  const [currentFilters, setCurrentFilters] = useState(filters)

  const { offendersAllData } = useAppSelector((state) => state.offenders)

  const { zipcodeFacet, stateFacet } = useMemo(() => {
    return offendersAllData || { zipcodeFacet: [], stateFacet: [] }
  }, [offendersAllData])

  useEffect(() => {
    let states: FilterOptions['options'] = []
    let zips: FilterOptions['options'] = []

    if (stateFacet && Object.keys(stateFacet).length) {
      states = Object.keys(stateFacet).map((key) => ({
        label: stateDataObj[key] || key,
        value: key,
      }))
    }

    if (zipcodeFacet && Object.keys(zipcodeFacet).length) {
      zips = Object.keys(zipcodeFacet).map((key) => ({
        label: key,
        value: key,
      }))
    }

    setCurrentFilters((prevState) => ({
      ...prevState,
      states: {
        ...prevState.states,
        options: states,
      },
      zipcodes: {
        ...prevState.zipcodes,
        options: zips,
      },
    }))
  }, [stateFacet, zipcodeFacet])

  const handleSearch = (name: keyof FilterCategory, value: string) => {
    setSearch((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleCheckboxChange = (
    category: FilterCategory,
    value: string,
    checked: boolean,
  ) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: checked
        ? [...prev[category], value]
        : prev[category].filter((v) => v !== value),
    }))
  }

  const handleSelectAll = (category: FilterCategory, checked: boolean) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: checked
        ? currentFilters[category].options?.map((el) => el.value) || []
        : [],
    }))
  }

  const handleReset = (category: FilterCategory) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [category]: [],
    }))
  }

  const handleSidebarToggle = () => {
    document.querySelector('body')?.classList.toggle('--toggle-side')
  }

  const handleSidebarClose = () => {
    document.querySelector('body')?.classList.remove('--toggle-side')
  }
  useEffect(() => {
    const overlay = document.querySelector('.custom-overlay.side-toggler-js')
    overlay?.addEventListener('click', handleSidebarClose)
    return () => overlay?.removeEventListener('click', handleSidebarClose)
  }, [])

  return (
    <div className="catalog-page-aside--wrapper">
      <button
        onClick={handleSidebarToggle}
        className="btn btn--square btn--xl btn-aside side-toggler-js"
      >
        <span className="ico">
          <FilterIcon />
        </span>
      </button>
      <div className="catalog-page-aside scroll-styler">
        <div className="aside-header header-similar">
          <h2 className="heading font-500">Filters</h2>
        </div>
        <div className="aside-body">
          <ul className="filters-list">
            {Object.entries(currentFilters).map(
              ([key, { title, icon, options, min, max }]) => (
                <li key={key} className="filters-list--item">
                  <Accordion
                    title={title}
                    onReset={
                      selectedFilters[key as FilterCategory]?.length
                        ? () => handleReset(key as FilterCategory)
                        : undefined
                    }
                    icon={icon}
                  >
                    {options ? (
                      <div className="filter-results">
                        <div className="form-group input--sm input--icon-left">
                          <div className="input-wrapper">
                            <span className="ico">
                              <MagnifyIcon />
                            </span>
                            <input
                              onChange={(e) =>
                                handleSearch(
                                  key as keyof Exclude<
                                    FilterCategory,
                                    'ranges'
                                  >,
                                  e.target.value,
                                )
                              }
                              value={
                                search[key as Exclude<FilterCategory, 'ranges'>]
                              }
                              className="input input--solid rounded-full w-full"
                              type="text"
                              placeholder={`Search ${title.toLowerCase()}...`}
                            />
                          </div>
                        </div>
                        <ul className="filter-results--list scroll-styler">
                          {options.length ? (
                            <li className="filter-results--list--item">
                              <Checkbox
                                text="Select All"
                                checked={options.every((option) =>
                                  selectedFilters[
                                    key as FilterCategory
                                  ].includes(option.value as never),
                                )}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleSelectAll(
                                    key as FilterCategory,
                                    e.target.checked,
                                  )
                                }
                              />
                            </li>
                          ) : null}
                          {options
                            .filter((option) =>
                              option.label.includes(
                                search[
                                  key as Exclude<FilterCategory, 'ranges'>
                                ],
                              ),
                            )
                            .map((option, index) => (
                              <li
                                key={index}
                                className="filter-results--list--item"
                              >
                                <Checkbox
                                  text={option.label}
                                  checked={selectedFilters[
                                    key as FilterCategory
                                  ].includes(option.value as never)}
                                  onChange={(
                                    e: ChangeEvent<HTMLInputElement>,
                                  ) =>
                                    handleCheckboxChange(
                                      key as FilterCategory,
                                      option.value,
                                      e.target.checked,
                                    )
                                  }
                                />
                              </li>
                            ))}
                        </ul>
                      </div>
                    ) : (
                      <div className="widget-range">
                        <InputRange
                          min={min || 0}
                          max={max || 100}
                          values={
                            selectedFilters.ranges.length
                              ? selectedFilters.ranges
                              : filters.ranges.defaultValues
                          }
                          tipFormatter={(value) => `${value} years`}
                          onChangeComplete={(values) =>
                            setSelectedFilters((prev) => ({
                              ...prev,
                              ranges: values,
                            }))
                          }
                        />
                      </div>
                    )}
                  </Accordion>
                </li>
              ),
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SidebarFilters

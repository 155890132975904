/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, ChangeEvent, MouseEvent } from 'react'

import MagnifyIcon from '../assets/img/ico-magnify.svg?react'
import NavigationIcon from '../assets/img/ico-navigation.svg?react'

import Button from './Button'
import { useLocation } from 'react-router-dom'

interface SearchFormProps {
  onSearch: (query: string, isNameSearch: boolean) => void
}

const SearchForm: React.FC<SearchFormProps> = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isNameSearch, setIsNameSearch] = useState<boolean>(true)

  const params = useLocation()
  const showSwitcher = !params.pathname.includes('settings')

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleToggle = () => {
    setIsNameSearch((prev) => !prev)
    setSearchQuery('')
  }

  const handleSearch = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    onSearch(searchQuery, isNameSearch)
  }

  return (
    <form className="search-form">
      {showSwitcher && (
        <label className="switch btn btn--lg">
          <input
            type="checkbox"
            className="hidden"
            checked={!isNameSearch}
            onChange={handleToggle}
          />
          <span className="switch-active">
            <span className="switch-active--bg"></span>
          </span>
          <div className="switch-controls">
            <div className="switch-control">
              <span className="switch-control--text">By Name</span>
            </div>
            <div className="switch-control">
              <span className="switch-control--text">By City (ZIP)</span>
            </div>
          </div>
        </label>
      )}
      <div className="form-group input--lg input--icon-left input--icon-right search-input">
        <div className="input-wrapper">
          <span className="ico">
            <MagnifyIcon />
          </span>
          <input
            className="input input--solid rounded-full w-full"
            type="text"
            placeholder="Search by name or zip..."
            value={searchQuery}
            onChange={handleInputChange}
          />
          <span className="ico">
            <NavigationIcon />
          </span>
        </div>
      </div>
      <div className="flex gap--xs items-center">
        <Button disabled={!searchQuery} onClick={handleSearch}>
          Search
        </Button>
      </div>
    </form>
  )
}

export default SearchForm

import clsx from 'clsx'
import type { ButtonHTMLAttributes } from 'react'
import React, { useState } from 'react'

import Spinner from './Spinner'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
}

const Button: React.FC<ButtonProps> = ({ children, ...rest }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsLoading(true)
    rest.onClick(event)
    setIsLoading(false)
  }

  return (
    <button
      {...rest}
      // disabled={isLoading}
      onClick={handleClick}
      className={clsx(
        'btn btn--primary btn--lg radius-full w-full',
        rest.className,
      )}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  )
}

export default Button

import { useGetMeQuery } from './services/auth'
import { useEffect } from 'react'
import { useLazyGetAllNotificationsQuery } from './services/notifications'

export const ServiceComponent = () => {
  const { data } = useGetMeQuery()
  const [triggerNotifications] = useLazyGetAllNotificationsQuery()

  useEffect(() => {
    if (data?.data?.id) {
      triggerNotifications()
    }
  }, [data])

  return <></>
}

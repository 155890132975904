import React from 'react'

import NotificationsIcon from '../../../assets/img/ico-notification-2.svg?react'

interface NotificationProps {
  title: string
  content: string
}

const Notification = ({ title, content }: NotificationProps) => {
  return (
    <div className="flex items-center gap--md">
      <div className="fake-btn btn btn--outline-danger rounded-full btn--square btn--md">
        <span className="ico">
          <NotificationsIcon />
        </span>
      </div>
      <div className="notification-block">
        <p className="notification-title text--lg font-600">{title}</p>
        <p className="text--lg ">{content}</p>
      </div>
    </div>
  )
}

export default Notification

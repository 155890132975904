import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import Header from '../compoments/Header'

const MainLayout = () => {
  // const navigate = useNavigate()

  useEffect(() => {
    document.querySelector('body')?.classList.add('fullpage')
  }, [])

  // if (!window.localStorage.getItem('token')) {
  //   navigate('/login-email')
  // }

  return (
    <>
      <Header />
      <div className="custom-overlay side-toggler-js"></div>
      <div className="wrapper header-fixed">
        <Outlet />
      </div>
    </>
  )
}

export default MainLayout

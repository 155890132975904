import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const usersBase = 'api/radar-service/search'

export interface IBodyUsers {
  name?: string
  zip?: string
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_API_URL }),
  endpoints: (builder) => ({
    getUsers: builder.query<any, IBodyUsers>({
      query: (body) => {
        if (body?.name) {
          return `${usersBase}?name=${body.name}&miles=10`
        } else if (body?.zip) {
          return `${usersBase}?zipcode=${body.zip}&miles=10`
        } else {
          return `${usersBase}`
        }
      },
    }),
    getProfilesUsers: builder.mutation<any, any>({
      query: (body) => ({
        url: `api/radar-service/user/offender`,
        method: 'POST',
        body,
      }),
    }),
    //
    // getMe: builder.query<IUser, void>({
    //     query: () => `api/users/me`,
    // }),
  }),
})

export const {
  useGetProfilesUsersMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
} = usersApi

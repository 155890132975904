import React, { FC, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import type { StepsProps } from '..'
import Button from '../../../compoments/Button'
import FormHeader from '../../../compoments/FormHeader'
import Input from '../../../compoments/Input'
import PasswordInput from '../../../compoments/PasswordInput'
import SelectBox from '../../../compoments/SelectBox'
import SocialButtons from '../../../compoments/SocialButtons'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginSchema } from '../../../shemas/validationSchema'
import { useGetMeQuery, useLoginMutation } from '../../../services/auth'

const Step1: FC<StepsProps> = ({ setStep }) => {
  console.log('1')

  const navigate = useNavigate()

  const [loginApi] = useLoginMutation()

  const { refetch } = useGetMeQuery()

  const [isRequestSend, setIsRequestSend] = useState(false)

  const [resError, setResError] = useState<boolean | string>(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  })

  const onSubmit = async (data: { email: string; password: string }) => {
    try {
      setResError(false)
      setIsRequestSend(true)
      const res = await loginApi(data).unwrap()

      console.log(res)
      if (res.success) {
        window.localStorage.setItem('token', res.data.token)

        await refetch()
        navigate('/')
      } else {
        setResError(res.message)
      }
    } catch (e) {
      setResError('Something went wrong')
      console.error('Error during login:', e)
    } finally {
      setIsRequestSend(false)
    }
  }

  return (
    <>
      <FormHeader
        hasLogo
        heading="Login"
        description="Welcome back, fill in form to login account"
      />
      <div className="form-body">
        <div className="row">
          <div className="col-md-12 col-12 mb-22">
            <Input
              label="Email"
              placeholder="Enter your email..."
              {...register('email')}
              error={errors?.email?.message}
            />
          </div>
          <div className="col-md-12 col-12 mb-28">
            <PasswordInput
              label="Password"
              placeholder="Enter your password..."
              {...register('password')}
              error={errors?.password?.message}
              forgotPassword={
                <button
                  onClick={() => {
                    setStep(2)
                  }}
                  className="color-secondary text--sm fw-500"
                >
                  Forgot password?
                </button>
              }
            />
          </div>
          {resError && <p className="error-message mb-4">{resError}</p>}
          <div className="col-12 mb-44">
            <Button
              type="button"
              disabled={isRequestSend}
              onClick={handleSubmit(onSubmit)}
            >
              Login
            </Button>
          </div>
        </div>
      </div>

      <div className="form-footer">
        <div className="row">
          <div className="col-12 mb-28">
            <SocialButtons button="call" />
          </div>
          <div className="col-12">
            <div className="flex items-center justify-center gap-10">
              <span className="text--md">Don’t have an account?</span>
              <Link
                to="/create-account"
                className="color-secondary text--md fw-600"
              >
                Create an Account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Step1
